import React, { useEffect, useState, useContext } from "react"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Glide from "@glidejs/glide"
import { Parallax } from "react-parallax"
import Scrollmagic from "scrollmagic"
import { TweenLite } from "gsap"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
import moment from "moment"
import { useQuery, useLazyQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import Modal from "react-modal"
import ReactMarkdown from "react-markdown"
import { useMediaPredicate } from "react-media-hook"

import "../graphql/fragments"
import { StockContext } from "../context/stockContext"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { CardSlider } from "../components/card-slider/card-slider"
import { Cover } from "../components/cover/cover"
import { FormSMLHome } from "../components/forms-sml/forms-sml"
import { Container } from "../components/grid/grid"
import { HeadingBasic, HeadingColumn } from "../components/heading/heading"
import { StockSection } from "../components/stock-section/stock-section"
import { CardProject } from "../components/card-project/card-project"
import { replaceUnicode } from "../components/utils/utils"
import { Button } from "../components/buttons/buttons"
import { ReviveAd } from "../components/ads/reviveAds"

import coverHeroImage from "../assets/img/home/sml-plaza.png"
import defaultImg from "../assets/img/img_development-main.jpg"

import { stringToSlug } from "../utils/text-to-slug"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

// Queries
//prettier-ignore
const STATIC_QUERY = graphql`
  query {
    coverHeroMobile: file(
      relativePath: { eq: "home/bg_home_cover-hero-mobile-blur_square.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coverHeroBg: file(relativePath: { eq: "home/bg_home_cover-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    banjarWijaya: file(relativePath: { eq: "imgBanjarWijaya.webp" }) {
      childImageSharp {
        fluid(maxWidth: 738) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imgNewsDesktop: file(relativePath: { eq: "home/home-section-news.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 849) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    tabs: file(relativePath: { eq: "home/property_finder_banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wordPress {
      pageBy(uri: "home-id") {
        slug
        blocks {
          name
          ...CoreParagraphBlock
          ...CoreHeadingBlock
          ...CoreImageBlock
          ...CoreButtonBlockHome
          ...CoreShortcodeBlock
          ...CoreCoverBlock
          ...CoreGroupBlock
        }
        translation(language: ID){
          blocks{
            name
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreImageBlock
            ...CoreButtonBlockHome
            ...CoreShortcodeBlock
            ...CoreCoverBlock
            ...CoreGroupBlock
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        pop_up {
          title
          description
          ctaLabel
          ctaLink{
            title
            url
            target
          }
          status
          image {
            sourceUrl
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          opengraphTitle
          opengraphDescription
        }
      }

      projectTag(id: "featured", idType: SLUG) {
        projects(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
          nodes {
            excerpt
            title
            slug
            propertyTypes {
              nodes {
                name
                slug
              }
            }
            locations {
              nodes {
                name
              }
            }
            featuredImage {
              sourceUrl
            }
            translation(language: ID) {
              excerpt
              title
              slug
              propertyTypes {
                nodes {
                  name
                  slug
                }
              }
              locations {
                nodes {
                  name
                }
              }
              featuredImage {
                sourceUrl
              }
            }
          }
        }
      }

      menus {
        nodes {
          menuItems {
            nodes {
              url
              label
              childItems {
                nodes {
                  url
                  label
                  childItems {
                    nodes {
                      url
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }

      facilities(first: 100) {
        nodes {
          name
          slug
          facilityId
          projects {
            nodes {
              propertyTypes {
                nodes {
                  slug
                  name
                }
              }
            }
          }
        }
      }

      locations(first: 100) {
        nodes {
          locationId
          slug
          name
        }
      }

      propertyTypes(where: {language: ID}, first: 100) {
        nodes {
          name
          slug
          propertyTypeId
        }
      }

      projects(first: 1000) {
        edges {
          node {
            slug
            priceProduct{price}
          }
        }
      }
    }
  }
`

const GET_PROPERTY_TYPES = gql`
  query GetPropertyTypes {
    propertyTypes(where: {language: ID}, first: 100) {
      nodes {
        name
        slug
        propertyTypeId
      }
    }
  }
`

const GET_PINNED_NEWS = gql`
  query PinnedNews {
    posts(first: 6, where: { language: EN, tagSlugIn: "pinned" }) {
      edges {
        cursor
        node {
          id
          title
          status
          date
          slug
          featuredImage {
            sourceUrl
          }
          categories {
            nodes {
              name
              slug
            }
          }
          translation(language: ID) {
            id
            title
            status
            date
            slug
            featuredImage {
              sourceUrl
            }
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  }
`

const GET_ALL_NEWS = gql`
  query AllNews {
    posts(first: 6, where: { language: EN }) {
      edges {
        cursor
        node {
          id
          title
          status
          date
          slug
          featuredImage {
            sourceUrl
          }
          categories {
            nodes {
              name
              slug
            }
          }
          translation(language: ID) {
            id
            title
            status
            date
            slug
            featuredImage {
              sourceUrl
            }
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  }
`

const FIND_PROPERTY = gql`
  query Finder(
    $facilityId: [ID]
    $locationId: [ID]
    $typeId: [ID]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    projects(
      where: {
        facilityIn: $facilityId
        locationIn: $locationId
        propertyTypeIn: $typeId
      }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          databaseId
          title
          slug
          excerpt
          propertyTypes {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            caption
          }
          priceProduct {
            price
          }
        }
      }
      pageInfo {
        total
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
  }
`

// Popup Home
const ModalTag = ({ dataModal, handlingModal, thisModal, stylingModal }) => {
  const now = moment().utcOffset("+07:00")
  let counter = 0
  let developmentPopup = []
  let popupActiveList = []
  let keyList = []
  let keyShowing

  let alreadyDone = []
  const randomValueFromArray = myArray => {
    if (alreadyDone.length === 0) {
      for (var i = 0; i < myArray.length; i++) alreadyDone.push(i)
    }

    let randomValueIndex = Math.floor(Math.random() * alreadyDone.length)
    let indexOfItemInMyArray = alreadyDone[randomValueIndex]
    alreadyDone.splice(randomValueIndex, 1)

    return myArray[indexOfItemInMyArray]
  }

  dataModal && dataModal.map((item, i) => {
    if (
      moment(dataModal[i].endDate).utcOffset("+07:00", true) > now &&
      moment(dataModal[i].startDate).utcOffset("+07:00", true) <= now
    ) {
      popupActiveList.push({
        key: i,
        startDate: moment(item.startDate).utcOffset("+07:00", true),
        endDate: moment(item.endDate).utcOffset("+07:00", true),
        title: item.title,
        description: item.description,
        imageUrl: item.image.sourceUrl,
        ctaLabel: item.ctaLabel,
        ctaTarget: item.ctaLink.target,
        ctaTitle: item.ctaLink.title,
        ctaUrl: item.ctaLink.url,
      })
      keyList.push(i + "a")
    }
  })

  for (let j = 0; j < popupActiveList.length; j++) {
    if (popupActiveList.length > 1) {
      counter = counter + 1
      // keyList.sort(()=> Math.random() - 0.5)
      keyShowing = randomValueFromArray(keyList)
      developmentPopup.push(
        <Modal
          key={keyShowing.substring(0, 1)}
          isOpen={thisModal}
          onRequestClose={handlingModal}
          style={stylingModal}
          contentLabel="campaign modal"
          overlayClassName="modal-overlay-general"
        >
          <div className="modal-popup-home modal-body">
            <div className="popup-close w-bg">
              <i className="fal fa-times" onClick={handlingModal}></i>
            </div>
            <div className="container p-0">
              <div className="row">
                <div className="col-md-7 order-md-last modal-image">
                  <img
                    src={popupActiveList[keyList.indexOf(keyShowing)]?.imageUrl}
                    className="mw-100"
                    alt="modal banner"
                    id={stringToSlug(popupActiveList[keyList.indexOf(keyShowing)]?.title)}
                  />
                </div>
                <div className="col-md-5 order-md-first">
                  <div className="modal-content">
                    <h2>
                      {popupActiveList[keyList.indexOf(keyShowing)]?.title}
                    </h2>
                    <div className="markdown-content">
                      <ReactMarkdown
                        source={
                          popupActiveList[keyList.indexOf(keyShowing)]
                            ?.description
                        }
                        escapeHtml={false}
                      />
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        link={
                          popupActiveList[keyList.indexOf(keyShowing)]?.ctaUrl
                        }
                        target={
                          popupActiveList[keyList.indexOf(keyShowing)]
                            ?.ctaTarget
                        }
                        className="w-100 mt-4"
                      >
                        {popupActiveList[keyList.indexOf(keyShowing)]?.ctaLabel}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
      // break
    } else if (popupActiveList.length === 1) {
      counter = counter + 1
      developmentPopup.push(
        <Modal
          key={0}
          isOpen={thisModal}
          onRequestClose={handlingModal}
          style={stylingModal}
          contentLabel="campaign modal"
          overlayClassName="modal-overlay-general"
        >
          <div className="modal-popup-home modal-body">
            <div className="popup-close w-bg">
              <i className="fal fa-times" onClick={handlingModal}></i>
            </div>
            <div className="container p-0">
              <div className="row">
                <div className="col-md-7 order-md-last modal-image">
                  <img
                    src={popupActiveList[j].imageUrl}
                    className="mw-100"
                    alt="modal banner"
                    id={stringToSlug(popupActiveList[j].title)}
                  />
                </div>
                <div className="col-md-5 order-md-first">
                  <div className="modal-content">
                    <h2>{popupActiveList[j].title}</h2>
                    <div className="markdown-content">
                      <ReactMarkdown
                        source={popupActiveList[j].description}
                        escapeHtml={false}
                      />
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        link={popupActiveList[j].ctaUrl}
                        target={popupActiveList[j].ctaTarget}
                        className="w-100 mt-4"
                      >
                        {popupActiveList[j].ctaLabel}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    if (counter == 1) {
      break
    }
  }
  return developmentPopup
}

// Components
const SliderImage = ({ imageData }) =>
  typeof imageData === "string" ? (
    <div
      alt="test-image"
      className="slider-image"
      style={{
        background: `url(${imageData}) no-repeat center`,
        backgroundSize: "cover",
      }}
    />
  ) : (
    <Img alt="test-image" fluid={imageData} className="slider-image" />
  )

  const CardNews = ({ label, date, content, link, image, category, cardBodyClassName, linkCategory, pinned }) => {
    return (
      <div className="card card-news-home">
        {label && <p className="badge badge-primary color-primary">{label}</p>}
        <div className={`card-body ${cardBodyClassName}`}>
          <div className="row">
            <div className="col-md-4">
              <div className="d-img-news">
                {image && <img src={image} alt={replaceUnicode(content)} width="80"/>}
              </div>
              <div className="m-img-news">
                <Link to={`/news/${linkCategory}/`}><p className="card-category">{replaceUnicode(category)}</p></Link>
                {image && <img src={image} alt={replaceUnicode(content)} width="80"/>}
              </div>
            </div>
            <div className="col-md-8">
              <div className="d-detail-news">
                {pinned ? 
                  <p className="card-date">Recommended Article</p> : 
                  <p className="card-date"><i className="fa fa-calendar-alt"></i> {date}</p>
                }
                <Link to={link}><h5 className="card-title">{replaceUnicode(content)}</h5></Link>
                <Link to={`/news/${linkCategory}/`}><p className="card-category">{replaceUnicode(category)}</p></Link>
                <a href={link} className="btn btn-primary w-25">Baca Selengkapnya</a>
              </div>
              <div className="m-detail-news">
                <Link to={link}><h5 className="card-title">{replaceUnicode(content)}</h5></Link>
                <p className="card-date"><i className="fa fa-calendar-alt"></i> {date}</p>
                <a href={link} className="btn btn-primary w-25">Baca Selengkapnya</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

const createParams = (obj, prefix) => {
  let arr = []
  for (let p in obj) {
    if(obj[p] !== "" && obj[p] !== undefined){
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix : p
        const v = obj[p]

        arr.push(typeof v == "object" 
          ? createParams(v, k) 
          : encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
  }
  return arr.join("&")
}

// Home Page
const IndexPage = ({ location }) => {
  const { stock, setStock } = useContext(StockContext)
  const [homeSchema, setHomeSchema] = useState()
  const [glideIndex, setGlideIndex] = useState(0)
  const [findProperty, setFindProperty] = useState({
    location: null,
    propertyTypes: null,
    projects: null,
    fromPrice: null,
    toPrice: null,
    checkBoxValues: [],
  })
  
  let homeSchemaVar
  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  // min. price data
  const minPriceData = [
    {label: "Min. Price (Rp)", value: 0},
    {label: "Rp 500jt", value: 500000000},
    {label: "Rp 600jt", value: 600000000},
    {label: "Rp 700jt", value: 700000000},
    {label: "Rp 800jt", value: 800000000},
    {label: "Rp 900jt", value: 900000000},
    {label: "Rp 1M", value: 1000000000},
    {label: "Rp 2M", value: 2000000000},
    {label: "Rp 3M", value: 3000000000},
    {label: "Rp 4M", value: 4000000000},
    {label: "Rp 5M", value: 5000000000},
    {label: "Rp 6M", value: 6000000000},
    {label: "Rp 7M", value: 7000000000},
    {label: "Rp 8M", value: 8000000000},
    {label: "Rp 9M", value: 9000000000},
    {label: "Rp 10M", value: 10000000000},
    {label: "Rp 11M", value: 11000000000},
    {label: "Rp 12M", value: 12000000000},
    {label: "Rp 13M", value: 13000000000},
    {label: "Rp 14M", value: 14000000000},
    {label: "Rp 15M", value: 15000000000},
    {label: "Rp 16M", value: 16000000000},
    {label: "Rp 17M", value: 17000000000},
    {label: "Rp 18M", value: 18000000000},
    {label: "Rp 19M", value: 19000000000},
    {label: "Rp 20M", value: 20000000000},
  ]
  
  // max. price data
  const maxPriceData = [
    {label: "Max. Price (Rp)", value: 0},
    {label: "Rp 500jt", value: 500000000},
    {label: "Rp 600jt", value: 600000000},
    {label: "Rp 700jt", value: 700000000},
    {label: "Rp 800jt", value: 800000000},
    {label: "Rp 900jt", value: 900000000},
    {label: "Rp 1M", value: 1000000000},
    {label: "Rp 2M", value: 2000000000},
    {label: "Rp 3M", value: 3000000000},
    {label: "Rp 4M", value: 4000000000},
    {label: "Rp 5M", value: 5000000000},
    {label: "Rp 6M", value: 6000000000},
    {label: "Rp 7M", value: 7000000000},
    {label: "Rp 8M", value: 8000000000},
    {label: "Rp 9M", value: 9000000000},
    {label: "Rp 10M", value: 10000000000},
    {label: "Rp 11M", value: 11000000000},
    {label: "Rp 12M", value: 12000000000},
    {label: "Rp 13M", value: 13000000000},
    {label: "Rp 14M", value: 14000000000},
    {label: "Rp 15M", value: 15000000000},
    {label: "Rp 16M", value: 16000000000},
    {label: "Rp 17M", value: 17000000000},
    {label: "Rp 18M", value: 18000000000},
    {label: "Rp 19M", value: 19000000000},
    {label: "Rp 20M", value: 20000000000},
  ]

  // server-side
  const data = useStaticQuery(STATIC_QUERY)

  // client-side
  const { data: dataPropertyTypes } = useQuery(GET_PROPERTY_TYPES)
  const { data: allNews } = useQuery(GET_ALL_NEWS)
  const { data: pinnedNews } = useQuery(GET_PINNED_NEWS)
  const [
    getProperty,
    { data: properties, loading: loadingProperties },
  ] = useLazyQuery(FIND_PROPERTY)

  // data variables
  const wordpress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const developments =
    data.wordPress?.projectTag?.projects?.nodes?.length > 0 &&
    data.wordPress?.projectTag?.projects?.nodes
  const newsItems = [
    ...(pinnedNews ? pinnedNews.posts.edges.map(item => ({...item, pinned: true})) : []), 
    ...(allNews ? allNews.posts.edges.filter((item)=>!pinnedNews?.posts?.edges?.map((item)=>item.cursor).includes(item.cursor)) : [])
  ].splice(0,6)
  const social =
    data.wordPress.menus && data.wordPress.menus.nodes[4].menuItems.nodes
  const listPropertyTypes = dataPropertyTypes?.propertyTypes?.nodes || data.wordPress.propertyTypes.nodes
  const listLocations = data.wordPress.locations.nodes
  const listFacilities = data.wordPress.facilities.nodes
  let propertyTypes = []
  listPropertyTypes.map(item =>
    item.slug !== "doubledream-faq" && 
    item.slug !== "faq" && 
    item.slug !== "infiniteliving-faq" && 
    item.slug !== "properti" && 
    item.slug !== "doubledream-property" && 
    item.slug !== "smartmove-faq" && 
    item.slug !== "smartmove-property" && 
    propertyTypes.push({ value: item.slug, label: item.name })
  )
  let listProperties = []

  properties &&
    properties.projects.edges.map(item => {
      listProperties.push({ label: item.node.title, value: item.node.slug })
    })

  let carouselData = []

  developments &&
    developments.map(item => {
      carouselData.push({
        image: item.featuredImage ? item.featuredImage.sourceUrl : defaultImg,
        propertyName: item.title || "Title",
        type: item.propertyTypes ? item.propertyTypes.nodes[0].name : null,
        location: item.locations ? item.locations.nodes[0].name : null,
        description: item?.translation?.excerpt || item?.excerpt,
        linkTo: `/development/${item.propertyTypes ? item.propertyTypes.nodes[0].slug : ""
          }/${item.slug}`,
      })
      return 1
    })

  const categoryProjectIcon = () => {
    let categoryIcon = ""
    switch (developments[glideIndex]?.translation?.propertyTypes?.nodes[0]?.name.toLowerCase()
      || developments[glideIndex]?.propertyTypes?.nodes[0]?.name.toLowerCase()){
      case "doubledream property":
        categoryIcon = "fal fa-home-lg-alt";
        break;
      case "(id) residential":
        categoryIcon = "fal fa-home-lg-alt";
        break;
      case "residential":
        categoryIcon = "fal fa-home-lg-alt";
        break;
      case "hospitality":
        categoryIcon = "fas fa-hotel";
        break;
      case "office":
        categoryIcon = "far fa-building";
        break;
      case "commercial" || "ruko":
        categoryIcon = "fas fa-city";
        break;
      case "township":
        categoryIcon = "fas fa-city";
        break;
    }
    return categoryIcon
  }

  let locations = []
  const allLocation = {value:"", label: "All Location"}
  data.wordPress.locations &&
    data.wordPress.locations.nodes.map(item =>
      locations.push({ value: item.slug, label: item.name })
    )
  locations.splice(0, 0, allLocation)
  
  const maxPriceDefault = maxPriceData[maxPriceData.length - 1].value

  useEffect(() => {
    homeSchemaVar = [
      {  
        "@context":"https://schema.org",
        "@type":"Place",
        "name":"Sinar Mas Land",
        "legalName":"PT BUMI SERPONG DAMAI TBK",
        "url":"https://www.sinarmasland.com/",
        "logo":"https://www.sinarmasland.com/static/logo_sinarmas-dark-2d2bb249299fda48ad4a9d199e44c467.png",
        "slogan":"building for a better future", 
        "address": {  
          "@type":"PostalAddress",
          "streetAddress":"Sinar Mas Land Plaza Jl. Grand Boulevard BSD City, Sampora, Kec. Cisauk, Tangerang, Banten 15345",
          "addressLocality":"BSD City Tangerang",
          "addressRegion":"ID-JK",
          "postalCode":"15345",
          "addressCountry":"ID"
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+6221-50368368",
          "contactType": "Customer service",
          "email": "info@sinarmasland.com"
        },
        "sameAs" : [
          "https://www.facebook.com/sinarmasland/",
          "https://twitter.com/sinarmas_land",
          "https://www.instagram.com/sinarmas_land/",
          "https://www.youtube.com/channel/UCVxIPn2_4HYa18VclGzIqKg",
          "https://www.tiktok.com/@sinarmas_land"
        ]
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Breadcrumbs",
            "item": {
              "@type": "Thing",
              "@id": "https://www.sinarmasland.com/"
            }
          }
        ]
      },
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://www.sinarmasland.com/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": `https://www.sinarmasland.com/search-result?key=${sessionStorage.getItem("search_key") ? sessionStorage.getItem("search_key") : ""}`, 
          "query-input": "required name=search_term_string"
        }
      },
      {
        "@context" : "https://schema.org",
        "@type" : "Organization",
        "name" : "Sinarmasland.com",
        "url" : "https://www.sinarmasland.com/",
        "logo": "https://www.sinarmasland.com/static/logo_sinarmas-dark-2d2bb249299fda48ad4a9d199e44c467.png",
        "sameAs" : [
          "https://www.facebook.com/sinarmasland/",
          "https://twitter.com/sinarmas_land",
          "https://www.instagram.com/sinarmas_land/",
          "https://www.youtube.com/channel/UCVxIPn2_4HYa18VclGzIqKg",
          "https://www.tiktok.com/@sinarmas_land"
        ]
      },
    ]

    setHomeSchema(homeSchemaVar)
  }, [])

  useEffect(() => {
    let stockBSDE
    let stockDUTI
    let stockA26

    if (stock.bsde === 0 && stock.duti === 0 && stock.a26 === 0) {
      fetch(
        "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/BSDE.JK/1d",
        {
          method: "GET",
          headers: {
            "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
            "x-rapidapi-key": `${process.env.YAHOO_FINANCE_KEY}`,
          },
        }
      )
        .then(response => response.json())
        .then(responseJson => responseJson && (stockBSDE = responseJson))
        .then(() => {
          fetch(
            "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/DUTI.JK/1d",
            {
              method: "GET",
              headers: {
                "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
                "x-rapidapi-key": `${process.env.YAHOO_FINANCE_KEY}`,
              },
            }
          )
            .then(duti => duti.json())
            .then(dutiJson => dutiJson && (stockDUTI = dutiJson))
            .then(() => {
              fetch(
                "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/A26.SI/1d",
                {
                  method: "GET",
                  headers: {
                    "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
                    "x-rapidapi-key": `${process.env.YAHOO_FINANCE_KEY}`,
                  },
                }
              )
                .then(a26 => a26.json())
                .then(a26Json => a26Json && (stockA26 = a26Json))
                .then(() => {
                  if (stockBSDE && stockDUTI && stockA26) {
                    setStock({
                      bsde: stockBSDE,
                      duti: stockDUTI,
                      a26: stockA26,
                    })
                  }
                })
                .catch(err2 => console.log(err2))
            })
            .catch(err1 => console.log(err1))
        })
        .catch(err => {
          console.log(err)
        })
    }

    // ======================== glide ========================

    const glide = new Glide("#featuredDevelopment", {
      focusAt: 0.65,
      perView: 2.2,
      breakpoints: {
        1200: {
          perView: 2,
        },
        992: {
          perView: 1.5,
        },
      },
    })
    glide.mount()
    glide.on(["mount.after", "run"], function () {
      setGlideIndex(glide.index)
    })

    // Tabs
    const tabs = document.querySelectorAll("[data-tab-target]")
    const tabContents = document.querySelectorAll("[data-tab-content]")
    tabs.forEach(tab => {
      tab.addEventListener("click", () => {
        const target = document.querySelector(tab.dataset.tabTarget)

        tabContents.forEach(tabContent => {
          tabContent.classList.remove("active")
        })

        tabs.forEach(tab => {
          tab.classList.remove("active")
        })

        tab.classList.add("active")
        target.classList.add("active")
      })
    })

    // Scroll Magic
    ScrollMagicPluginGsap(Scrollmagic, TweenLite)
    const controller = new Scrollmagic.Controller({
      globalSceneOptions: {
        triggerHook: 0.1,
        duration: "110%",
      },
    })
    new Scrollmagic.Scene({
      triggerElement: ".scroll-trigger",
    })
      .setPin(".scroll-trigger", { pushFollowers: false })
      .setTween("#newsHeader", { opacity: 0.4 })
      .addTo(controller)

    return () => {
      glide.destroy()
      tabs.forEach(tab => {
        tab.removeEventListener("click", () => {
          const target = document.querySelector(tab.dataset.tabTarget)
          tabContents.forEach(tabContent => {
            tabContent.classList.remove("active")
          })
          tabs.forEach(tab => {
            tab.classList.remove("active")
          })
          tab.classList.add("active")
          target.classList.add("active")
        })
      })
    }
  }, [])

  useEffect(() => {
    let facilities = []
    let locations = []
    let propertyTypeId =
      findProperty.propertyTypes &&
      listPropertyTypes.find(x => x.slug === findProperty.propertyTypes.value)
        .propertyTypeId

    findProperty.location &&
      listLocations.map(
        item =>
          item.name === findProperty.location.label &&
          locations.push(item.locationId)
      )

    findProperty.checkBoxValues.length > 0 &&
      findProperty.checkBoxValues.map(item => {
        listFacilities.map(facility => {
          if (facility.slug === item) {
            facilities.push(facility.facilityId)
          }
        })
      })

    if (locations.length > 0 && propertyTypeId) {
      getProperty({
        variables: {
          locationId: locations.length > 0 ? locations : null,
          typeId: [propertyTypeId],
          first: 1000,
          facilityId: facilities.length > 0 ? facilities : null,
        },
      })
    }
  }, [findProperty])

  useEffect(() => {
    if (findProperty.location && findProperty.propertyTypes && findProperty.projects) {
      if(findProperty.propertyTypes.value === "properti"){
        navigate(`/wishforhome/${findProperty.location.value}/${findProperty.projects.value}`)
      }else if(findProperty.propertyTypes.value === "kavling"){
        navigate(`/wishforhome/${findProperty.propertyTypes.value}/${findProperty.location.value}/${findProperty.projects.value}`)
      }else{
        navigate(`/development/${findProperty.propertyTypes.value}/${findProperty.projects.value}`)
      }
    }
  }, [findProperty])

  const locationHandler = e => {
    setFindProperty({ ...findProperty, location: e })
  }

  const propertyTypeHandler = e => {
    setFindProperty({ ...findProperty, propertyTypes: e })
  }

  const projectsHandler = e => {
    setFindProperty({ ...findProperty, projects: e })
  }

  const fromPriceHandler = e => {
    setFindProperty({ ...findProperty, fromPrice: e })
  }

  const toPriceHandler = e => {
    setFindProperty({ ...findProperty, toPrice: e })
  }

  const checkBoxValuesHandler = e => {
    let checkBoxValuesTemp = findProperty.checkBoxValues
    e.checked && checkBoxValuesTemp.push(e.value)
    //prettier-ignore
    !e.checked && checkBoxValuesTemp.splice(checkBoxValuesTemp.indexOf(e.value), 1)
    setFindProperty({ ...findProperty, checkBoxValues: checkBoxValuesTemp })
  }

  const propertyFinderSubmit = e => {
    e.preventDefault()
    let priceRanges = {}

    if((findProperty?.fromPrice?.value !== undefined && findProperty?.toPrice?.value !== undefined)){
      if(findProperty?.fromPrice?.value < findProperty?.toPrice?.value){
        priceRanges = {
          fromPrices: findProperty?.fromPrice?.value,
          toPrices: findProperty?.toPrice?.value,
        }
      }else{
        priceRanges = {
          fromPrices: 0,
          toPrices: findProperty?.toPrice?.value,
        }
      }
    }else if(
      (findProperty?.fromPrice?.value === undefined && findProperty?.toPrice?.value !== undefined)
    ){
      priceRanges = {
        fromPrices: 0,
        toPrices: findProperty?.toPrice?.value,
      }
    }else if(
      (findProperty?.fromPrice?.value !== undefined && findProperty?.toPrice?.value === undefined)
    ){
      priceRanges = {
        fromPrices: findProperty?.fromPrice?.value,
        toPrices: maxPriceDefault,
      }
    }

    if (
      findProperty.location &&
      findProperty.propertyTypes &&
      !findProperty.projects
    ) {
      navigate("/id/search-price-result", {
        state: {
          propertyType: findProperty.propertyTypes,
          values: {
            location: findProperty.location,
            residenceFacilities: findProperty.checkBoxValues,
            priceRanges,
          },
        },
      })
    } else if (
      findProperty.location &&
      findProperty.propertyTypes &&
      findProperty.projects
    ) {
      navigate(
        `/development/${findProperty.propertyTypes.value}/${findProperty.projects.value}`
      )
    } else if (
      findProperty.location &&
      !findProperty.propertyTypes &&
      !findProperty.projects
    ) {
      navigate("/id/search-price-result", {
        state: {
          values: {
            location: findProperty.location,
            residenceFacilities: findProperty.checkBoxValues,
            priceRanges,
          },
        },
      })
    } else if (
      !findProperty.location &&
      findProperty.propertyTypes &&
      !findProperty.projects
    ) {
    console.log("ke sini dong");
      navigate("/id/search-price-result", {
        state: {
          propertyType: findProperty.propertyTypes,
          values:{
            residenceFacilities: findProperty.checkBoxValues,
            priceRanges,
          },
        },
      })
    }else if (
      !findProperty.location &&
      !findProperty.propertyTypes &&
      !findProperty.projects
    ){
      navigate("/id/search-price-result", {
        state: {
          values:{
            priceRanges,
            residenceFacilities: findProperty.checkBoxValues,
          },
        },
      })
    }
  }

  return (
    <>
    <Layout location={location} currentLocation={location.pathname} footerLogo={false} lang="ID">
      <SEO
        location={location}
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          data.coverHeroBg.childImageSharp.fluid ||
          data.coverHeroMobile.childImageSharp.fluid
        }
        twitterImg={
          data.coverHeroBg.childImageSharp.fluid ||
          data.coverHeroMobile.childImageSharp.fluid
        }
        img={
          data.coverHeroBg.childImageSharp.fluid ||
          data.coverHeroMobile.childImageSharp.fluid
        }
        // schemaMarkup={homeSchema}
      />
      {wordpress && (
        <>
          <Cover
            variant="responsive"
            title={wordpress && wordpress[0].innerBlocks[0].attributes.content}
            text={wordpress && wordpress[0].innerBlocks[1].attributes.content}
            btnText={
              wordpress &&
              wordpress[0].innerBlocks[2].innerBlocks[0].attributes.text
            }
            btnLink={`id/${wordpress &&
              wordpress[0].innerBlocks[2].innerBlocks[0].attributes.url}`}
            btnClassName="font-size-lg mb-n3"
            img={data.coverHeroMobile.childImageSharp.fluid}
            imgHeight="h-ratio-1-1 h-md-400px"
            contentMaxWidth="mw-lg-500px"
            className="d-block d-lg-none pb-main cover-full-top cover-home"
            theme="light"
          />
          {/* Home Desktop */}
          <BackgroundImage
            Tag="section"
            fluid={data.coverHeroBg.childImageSharp.fluid}
            className="d-none d-lg-block cover-full-top cover-hero-wrapper"
          >
            <Parallax
              strength={400}
              bgImage={coverHeroImage}
              bgImageAlt="Home Banner"
              className="parallax"
            >
              <div className="cover-hero d-flex align-items-center">
                <Container>
                  <div className="row">
                    <div className="col-7">
                      <h1 className="cover-title animated fadeInUp">
                        {wordpress &&
                          wordpress[0].innerBlocks[0].attributes.content}
                      </h1>
                      <p className="cover-text animated fadeInUp delyp1">
                        {wordpress &&
                          wordpress[0].innerBlocks[1].attributes.content}
                      </p>
                      <Link
                        to={`/id${wordpress &&
                          wordpress[0].innerBlocks[2].innerBlocks[0].attributes
                            .url}`}
                        className="btn btn-link fx-underline animated fadeInUp delayp2"
                      >
                        {wordpress &&
                          wordpress[0].innerBlocks[2].innerBlocks[0].attributes
                            .text}{" "}
                      </Link>
                    </div>
                  </div>
                </Container>
                <div className="cover-hero-social">
                  {social &&
                    social.map((item, i) => {
                      switch (item.label.toLowerCase()) {
                        case "facebook":
                          return (
                            // prettier-ignore
                            <a className='gtm-fb' href={item.url} target="_blank" rel="noopener noreferrer" key={`footer-socmed-${i}`}><i className="fab fa-facebook-f"></i></a>
                          )
                        case "twitter":
                          return (
                            // prettier-ignore
                            <a className='gtm-twitter' href={item.url} target="_blank" rel="noopener noreferrer" key={`footer-socmed-${i}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                              </svg>
                            </a>
                          )
                        case "instagram":
                          return (
                            // prettier-ignore
                            <a className='gtm-ig' href={item.url} target="_blank" rel="noopener noreferrer" key={`footer-socmed-${i}`} > <i className="fab fa-instagram"></i> </a>
                          )
                        case "linkedin":
                          return (
                            // prettier-ignore
                            <a href={item.url} target="_blank" rel="noopener noreferrer" key={`footer-socmed-${i}`} > <i className="fab fa-linkedin"></i> </a>
                          )
                        case "tiktok":
                          return (
                            // prettier-ignore
                            <a className='gtm-tiktok' href={item.url} target="_blank" rel="noopener noreferrer" key={`footer-socmed-${i}`} > 
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                              </svg>
                            </a>
                          )
                        case "youtube":
                          // prettier-ignore
                          return <a className='gtm-youtube' href={item.url} target="_blank" rel="noopener noreferrer" key={`footer-socmed-${i}`} > <i className="fab fa-youtube"></i> </a>
                        default:
                          return <div key={`empty-social-${i}`}></div>
                      }
                    })}
                </div>
                <p className="cover-vertical-font">SinarmasLand Plaza</p>
              </div>
            </Parallax>
          </BackgroundImage>

          {/* DEVELOPMENT */}
          {/* Development Large */}
          <section className="home-development d-none d-lg-block">
            <Container className="py-main px-0">
              <HeadingColumn
                // caption="Developments"
                caption={
                  wordpress && wordpress[1].innerBlocks[0].attributes.content
                }
                text={[
                  wordpress && wordpress[1].innerBlocks[1].attributes.content,
                ]}
                className="mb-0"
              />
            </Container>
            <div className="development-carousel-wrapper d-none d-lg-block overflow-x-hidden">
              <div className="carousel-frame container">
                <div className="row">
                  <div className="col-1 d-flex justify-content-end px-0">
                    <div className="development-separator d-none d-xl-block"></div>
                  </div>
                  <div className="col-xl-3 col-lg-3 px-0">
                    <div className="development-detail">
                      <h4 className="card-title">
                        {replaceUnicode(
                          developments[glideIndex]?.translation?.title ||
                          developments[glideIndex].title
                        )}
                      </h4>
                      <div className="card-data">
                        <div>
                          <i class={categoryProjectIcon()}></i>{" "}
                          <span>
                            {developments[glideIndex]?.translation
                              ?.propertyTypes?.nodes[0]?.name ||
                              developments[glideIndex]?.propertyTypes?.nodes[0]
                                ?.name}
                          </span>
                        </div>
                        <div>
                          <i class="far fa-map"></i>{" "}
                          <span>
                            {developments[glideIndex].locations.nodes[0].name}
                          </span>
                        </div>
                      </div>
                      <p class="card-text">
                        {replaceUnicode(
                          developments[glideIndex]?.translation?.excerpt ||
                          developments[glideIndex]?.excerpt
                        )}
                      </p>
                      <Link
                        to={`/id/development/${developments &&
                          developments[glideIndex].propertyTypes.nodes[0]
                            .slug}/${developments &&
                            developments[glideIndex].slug}`}
                      >
                        <button className="btn btn-link text-white fx-underline">
                          {"Jelajahi Lebih Lanjut"}
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-8 col-xl-7 development-padding d-flex justify-content-end">
                    <div className="development-separator d-none d-lg-block"></div>
                  </div>
                </div>
              </div>
              <div className="container px-0 px-lg-3">
                <div id="featuredDevelopment" className="glide">
                  <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                      {developments &&
                        developments.map((item, i) => (
                          <li className="glide__slide">
                            <SliderImage
                              imageData={
                                item.featuredImage
                                  ? item.featuredImage.sourceUrl
                                  : data.banjarWijaya.childImageSharp.fluid
                              }
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="glide-btn" data-glide-el="controls">
                    <div className="glide-btn-left" data-glide-dir="<">
                      <i className="fal fa-angle-right font-size-lg text-white" />
                    </div>
                    <div className="glide-btn-right" data-glide-dir=">">
                      <i className="fal fa-angle-right font-size-lg text-white" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="development-carousel-progress d-none d-lg-block">
                <div className="container px-0 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="progress-bar">
                      {developments && (
                        <div
                          className="progress-bar-completed"
                          style={{
                            width:
                              glideIndex === 0
                                ? `${(1 / developments.length) * 100}%`
                                : `${((glideIndex + 1) / developments.length) *
                                100}%`,
                          }}
                        ></div>
                      )}
                    </div>
                    {developments && (
                      <>
                        <span className="ml-4 progress-current">
                          {glideIndex + 1 < 10
                            ? `0${glideIndex + 1}`
                            : `${glideIndex + 1}`}
                          /
                        </span>
                        <span className="progress-total">
                          {developments.length < 10
                            ? `0${developments.length}`
                            : developments.length}
                        </span>
                      </>
                    )}
                  </div>
                  <Link
                    className="btn btn-link fx-underline font-size-lg"
                    to="/id/development"
                  >
                    {wordpress &&
                      wordpress[1].innerBlocks[2].innerBlocks[0].attributes
                        .text}
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* Development Small */}
          <section className="development d-lg-none overflow-x-hidden">
            <section className="bg-gray-50 py-main">
              <div className="container">
                <HeadingBasic
                  h2={
                    wordpress && wordpress[1].innerBlocks[0].attributes.content
                  }
                  text={[
                    wordpress && wordpress[1].innerBlocks[1].attributes.content,
                  ]}
                  btnText={"Lihat Semua Proyek"}
                  btnLink="/id/development"
                  className="mb-0"
                />
              </div>
            </section>
            <CardSlider
              noGutter
              showLgDown="3"
              arrowsMdDown
              arrowsSmDown
              className="card-slider-property"
            >
              {carouselData.map((item, index) => (
                <CardProject
                  title={item.propertyName || null}
                  description={
                    item?.translation?.description || item?.description
                  }
                  img={item.image || null}
                  category={item.type || null}
                  location={item.location || null}
                  link={`/id${item.linkTo || "/id"}`}
                  className="px-sm-down-0 ratio-1-1"
                />
              ))}
            </CardSlider>
          </section>

          {/* // PROPERTY SEARCH */}
          <section className="tab-section">
            <BackgroundImage
              Tag="div"
              fluid={data.tabs.childImageSharp.fluid}
              className="tabs-header"
            >
              <div className="tabs-header-title">
                <div className="container mw-lg">
                  <h3>{"Temukan properti yang sempurna untuk Anda"}</h3>
                </div>
              </div>
              <div class="bg-overlay bg-overlay-additional" />
              <div class="bg-overlay bg-overlay-30" />
            </BackgroundImage>

            {/* <div className="tabs-nav-wrapper">
          <div className="container mw-lg px-sm-down-0">
            <ul className="tabs-nav">
              <li data-tab-target="#residence" className="active">
                <span>Residence</span>
              </li>
              <li data-tab-target="#office">
                <span>Office</span>
              </li>
              <li data-tab-target="#retail">
                <span>Retail</span>
              </li>
            </ul>
          </div>
        </div> */}
          </section>
          <section className="tab-content-section">
            <div className="container mw-lg px-lg-up-0">
              <div className="tab-content-wrapper">
                <FormSMLHome
                  locations={locations}
                  propertyTypes={propertyTypes}
                  projects={listProperties}
                  fromPrice={minPriceData}
                  toPrice={maxPriceData}
                  valueLocation={findProperty.location ? findProperty.location.value.toLowerCase().split(" ").join("-") : " "}
                  valuePropertyType={findProperty.propertyTypes ? findProperty.propertyTypes.value.toLowerCase().split(" ").join("-") : ""}
                  valueMinPrice={findProperty.fromPrice ? findProperty.fromPrice.value : ""}
                  valueMaxPrice={findProperty.toPrice ? findProperty.toPrice.value : ""}
                  onCheckBoxChange={checkBoxValuesHandler}
                  onLocationChange={locationHandler}
                  onPropertyTypesChange={propertyTypeHandler}
                  onProjectsChange={projectsHandler}
                  onFromPriceChange={fromPriceHandler}
                  onToPriceChange={toPriceHandler}
                  onSubmit={propertyFinderSubmit}
                  isLoading={loadingProperties}
                />
                {/* <FormOffice locations={locations} /> */}
                {/* <div id="office" data-tab-content>
            </div>
            <div id="retail" data-tab-content>
              <FormRetail locations={locations} />
            </div> */}
              </div>
            </div>
          </section>

          {/* placement banner */}
          <div className="d-none d-lg-block">
            <ReviveAd
              zoneId="1"
              reviveId="4d3dce4db16ff385dd1c5dd5ade84a88"
            />
          </div>
          <div className="d-block d-lg-none">
            <ReviveAd
              zoneId="16"
              reviveId="4d3dce4db16ff385dd1c5dd5ade84a88"
            />
          </div>

          {/* NEWS */}
          {/* News - Desktop */}
          <section className="news-desktop dark pb-main">
            <div className="scroll-trigger">
              {newsItems && (
                <BackgroundImage
                  id="newsHeader"
                  Tag="div"
                  fluid={data.imgNewsDesktop.childImageSharp.fluid}
                  className="news-featured"
                  style={{ overflow: "hidden", borderRadius: "0" }}
                >
                  <div className="card-featured-bg"></div>
                  <div className="bg-overlay bg-overlay-additional" />
                  <div className="bg-overlay bg-overlay-40" />
                </BackgroundImage>
              )}
            </div>
            <div className="news-section">
              <div className="container mw-lg">
                <div className="news-section-content">
                  <h2 className="h2">{"Berita"}</h2>
                  <div className="news-home-wrapper">
                    {newsItems &&
                      newsItems.map((item, i) => {
                        if (i <= 5 && i > 0) {
                          return (
                            <CardNews
                              cardBodyClassName={i < 5 ? `card-underline` : ``}
                              category={item?.node?.categories?.nodes[0].name}
                              linkCategory={item?.node?.categories?.nodes[0].slug}
                              date={moment(item.node.date).format("MMM Do, YYYY")}
                              content={item.node.title.split("#038;").join("")}
                              link={`/news/${item.node.slug}/`}
                              image={item?.node?.featuredImage?.sourceUrl 
                                ? item?.node?.featuredImage?.sourceUrl
                                : "https://sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png"
                              }
                              pinned={item.pinned}
                            />
                          )
                        } else if (i === 0) {
                          return (
                            <CardNews
                              cardBodyClassName={`card-underline card-w-featured-news`}
                              category={item?.node?.categories?.nodes[0].name}
                              linkCategory={item?.node?.categories?.nodes[0].slug}
                              label="Featured News"
                              date={moment(item.node.date).format("MMM Do, YYYY")}
                              content={item.node.title}
                              link={`/news/${item.node.slug}/`}
                              image={item?.node?.featuredImage?.sourceUrl 
                                ? item?.node?.featuredImage?.sourceUrl
                                : "https://sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png"
                              }
                              pinned={item.pinned}
                            />
                          )
                        }
                        return -1
                      })}
                  </div>
                  <Link to="/id/news">
                    <button className="btn btn-primary">
                      {"Lihat Semua Berita"}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* INVESTORS */}
          <section className="investors-section">
            <div className="container container-md py-main">
              <HeadingBasic
                h2={wordpress && wordpress[6].innerBlocks[1].attributes.content}
                text={[wordpress[6].innerBlocks[2].attributes.content]}
                btnText={
                  wordpress &&
                  wordpress[6].innerBlocks[3].innerBlocks[0].attributes.text
                }
                btnLink={`/id${wordpress &&
                  wordpress[6].innerBlocks[3].innerBlocks[0].attributes.url}`}
                className="mb-0"
              />
            </div>
          </section>

          <StockSection
            stockPrice={
              stock.bsde !== 0 && stock.duti !== 0 && stock.a26 !== 0 && stock
            }
          />
        </>
      )}
    </Layout>
    <div className="html-modal-wrapper">
      <div className="d-none d-lg-block">
        <ReviveAd
          zoneId="5"
          reviveId="4d3dce4db16ff385dd1c5dd5ade84a88"
        />
      </div>
      <div className="d-block d-lg-none">
        <ReviveAd
          zoneId="15"
          reviveId="4d3dce4db16ff385dd1c5dd5ade84a88"
        />
      </div>
    </div>
    </>
  )
}

export default IndexPage
